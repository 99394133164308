import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';


const StyledLink = styled(({ ...props }) => (<Link {...props} />))`
   & .MuiButton-root{
    color: ${props => props.theme.palette.text.main};
    &:hover{
     color: ${props => props.theme.palette.text.focus};
    }
   }
`;

function RouteLink({ to, label, ...props }) {

    return (
        <StyledLink to={to} {...props}>{label}</StyledLink>
    )
}

export { RouteLink }
