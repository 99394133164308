import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { APP_NAME } from '../../../_helpers';
import { PreAuthRoutes } from '../../../routes';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { RegisterAction } from '../../../redux/actions';
import ConfirmEmailImage from '../confirm-email/ConfirmEmailImage.svg'
import { Modal } from '../../../_components';
import { InfluencerCompleteProfile } from './influencer-complete-profile';

function CompleteProfile() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { key } = useParams();

    /** Initialize and declare state */
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({ heading: '', description: '' });
    const [open, setOpen] = useState(false);

    /** Redux reducer states */
    const { complete_profile_loading: isLoading } = useSelector((state) => state.RegisterReducer);

    useEffect(() => {
        dispatch(RegisterAction.completeProfile({ key })).then((result) => {
            console.log(result.data);
            if (result.status && result.data && Object.keys(result.data).length > 0) {
                setData(result.data);
                setOpen(true);
            } else if (result.data.heading || result.data.description) {
                setErrors(result.data)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        // setOpen(false);
    };

    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('complete_profile').name}`}</title>
            </Helmet>
            <Grid container spacing={3} pt={20} pb={20}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} display={`flex`} justifyContent={`center`} alignItems={`center`}>
                            <Stack alignItems={`center`}>
                                <img src={ConfirmEmailImage} width="150" alt='confirm-email' />
                                <Typography variant="h1" align={`center`} pt={5}>{isLoading ? <Skeleton /> : (errors.heading.toUpperCase())}</Typography>
                                <Typography variant="body" align={`center`}>{isLoading ? <Skeleton /> : errors.description}</Typography>
                                <Modal isDialogOpen={open} onClose={handleCancel} maxWidth="sm" disableEscapeKeyDown={true}>
                                    <InfluencerCompleteProfile data={data} onClose={handleClose}/>
                                </Modal>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PreSignIn>
    )
}

export { CompleteProfile }