import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { APP_NAME } from '../../../_helpers';
import { PreAuthRoutes } from '../../../routes';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { RegisterAction } from '../../../redux/actions';
import ConfirmEmailImage from './ConfirmEmailImage.svg'

function ConfirmEmail() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { key: verificationString } = useParams();

    /** Initialize and declare state */
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({ heading: '', description: '' });

    useEffect(() => {
        dispatch(RegisterAction.confirmEmail(verificationString)).then((result) => {
            setIsLoading(false);
            setData(result.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('confirm_email').name}`}</title>
            </Helmet>
            <Grid container spacing={3} pt={20} pb={20}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} display={`flex`} justifyContent={`center`} alignItems={`center`}>
                            <Stack alignItems={`center`}>
                                <img src={ConfirmEmailImage} width="150" alt='confirm-email' />
                                <Typography variant="h1" align={`center`} pt={5}>{isLoading ? <Skeleton /> : (data.heading.toUpperCase())}</Typography>
                                <Typography variant="body" align={`center`}>{isLoading ? <Skeleton /> : data.description}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PreSignIn>
    )
}

export { ConfirmEmail }