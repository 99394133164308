/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import colors from "../base/colors";
import linearGradient from "../../functions/linearGradient";


const { gradients } = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    styleOverrides: {
        root: {
            background: linearGradient(gradients.dark.main, gradients.dark.state, 315),
        },
    },
};
