import React from 'react'
import { Card, CardContent, CardMedia, Grid, styled } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TestimonialsAction } from '../../../../redux/actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { TestimonialsCard } from './testimonials-card';
import testimonialLogo from './testimonial-logo.svg'
import 'swiper/css';
import { POST_BROKEN_IMAGE } from '../../../../_helpers';

// Styled header container
const StyledCardContent = styled(({ ...props }) => (<CardContent  {...props} />))`
    padding: 2.5rem !important;
`;


function Testimonials() {


    // const classes = useStyles();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { testimonials_loading: isLoading, testimonials } = useSelector((state) => state.TestimonialsReducer);
    const listTestimonials = () => dispatch(TestimonialsAction.listTestimonials());


    const getNext = async () => {
        await listTestimonials();
    }

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (testimonials.length === 0)
        return ''

    return (
        <Card>
            <StyledCardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} display={`flex`} alignItems={`center`} justifyContent={`center`} >
                        <CardMedia component="img" image={testimonialLogo} onError={e => { e.target.src = POST_BROKEN_IMAGE }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}  >
                        <Swiper spaceBetween={15} navigation autoplay={{ delay: 3000 }} modules={[Navigation, Pagination, Autoplay]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {!isLoading && testimonials.map((testimonial, i) => {
                                return (
                                    <SwiperSlide key={i} >
                                        <TestimonialsCard testimonial={testimonial} />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    )
}

export { Testimonials }