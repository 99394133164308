import { Box, CardMedia, Typography } from '@mui/material'
import testimonialLogo from './testimonial-card.svg'
import React from 'react'
import { AVATAR_BROKEN_IMAGE } from '../../../../../_helpers'

function TestimonialsCard({ testimonial }) {
    return (
        <Box sx={{ backgroundColor: `#163850` }}>
            <CardMedia component="img" image={testimonial.user.profile_picture_url} title={testimonial.user.name} height="150" sx={{ margin: `0 !important`, borderRadius: 0, width: `100%` }} onError={e => { e.target.src = AVATAR_BROKEN_IMAGE }} />
            <CardMedia component="img" image={testimonialLogo} style={{ width: `50px`, position: `absolute`, marginTop: `-25px` }} />
            <Box p={4} >
                <Box sx={{ minHeight: `150px` }}>
                    <Typography variant="subtitle2">{testimonial.message}</Typography>
                </Box>
                <Typography variant="h6">{testimonial.user.name}</Typography>
                <Typography variant="subtitle2">{testimonial.user_type.toUpperCase()}</Typography>
            </Box>
        </Box>
    )
}

export { TestimonialsCard }