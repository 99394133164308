import { RegisterServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const COMPLETE_PROFILE_REQUEST = 'COMPLETE_PROFILE_REQUEST';
export const COMPLETE_PROFILE_SUCCESS = 'COMPLETE_PROFILE_SUCCESS';
export const COMPLETE_PROFILE_FAILURE = 'COMPLETE_PROFILE_FAILURE';

export const COMPLETED_PROFILE_REQUEST = 'COMPLETED_PROFILE_REQUEST';
export const COMPLETED_PROFILE_SUCCESS = 'COMPLETED_PROFILE_SUCCESS';
export const COMPLETED_PROFILE_FAILURE = 'COMPLETED_PROFILE_FAILURE';

export const RegisterAction = {

    /**
    * Action for register brand
    * @param {Object} params - The params which are used for registration api.
    * @param {string} params.name - Name of the brand 
    * @param {string} params.insta_handle - Instagram handle of the brand
    * @param {string} params.email - Email of the brand 
    * @param {string} params.mobile_number - Mobile number entered by the brand 
    * @param {string} params.password - Password entered by the brand 
    * @param {string} params.confirm_password - Confirm password entered by the brand 
    * @author Akshay 
    */
    register: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.register(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: REGISTER_REQUEST, request: request } }
        function success(request, response) { return { type: REGISTER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: REGISTER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for sign out
    * @author Akshay 
    */
    confirmEmail: (verificationString) => {
        return dispatch => {
            return RegisterServices.confirmEmail(verificationString)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };

    },
    /**
    * Action for sign out
    * @author Akshay 
    */
    submitNewsLetter: (params) => {
        return dispatch => {
            return RegisterServices.submitNewsLetter(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return response; },
                    error => { return error; }
                );
        };

    },

    /**
    * Action for complete profile
    * @author Akshay 
    */
    completeProfile: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.completeProfile(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: COMPLETE_PROFILE_REQUEST, request: request } }
        function success(request, response) { return { type: COMPLETE_PROFILE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: COMPLETE_PROFILE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for complete profile success
    * @param {Object} params - The params which are used for registration api.
    * @param {string} params.name - Name of the brand 
    * @param {string} params.email - Email of the brand 
    * @param {string} params.mobile_number - Mobile number entered by the brand 
    * @param {string} params.password - Password entered by the brand 
    * @param {string} params.confirm_password - Confirm password entered by the brand 
    * @author Akshay 
    */
    completeProfileSuccess: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.completeProfileSuccess(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: COMPLETED_PROFILE_REQUEST, request: request } }
        function success(request, response) { return { type: COMPLETED_PROFILE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: COMPLETED_PROFILE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


};



