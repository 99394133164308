
import React, { useEffect } from 'react';
import { cookieServices } from '../../_helpers';
import { useHistory } from 'react-router';
import { Container } from '@mui/material';
import { Header } from '.'
import { PostAuthMainComponents } from '../../routes';
import { Route, Switch } from "react-router-dom";

const PostSignIn = ({ children, ...props }) => {

    let history = useHistory();
    
    const menuRoutes = Object.values(PostAuthMainComponents());

    useEffect(() => {

        if (!cookieServices.get('accessToken')) {
            history.push('/');
        }

    }, [history])


    return (
        <React.Fragment>
            <Header />
            <Container maxWidth="sm" component="main" style={{ marginBottom: `60px` }}>
                <Switch>
                    {menuRoutes.map((route, index) => (
                        <Route exact key={index} path={route.path} component={route.component} />
                    ))}
                </Switch>
            </Container>
        </React.Fragment>
    )
}


export { PostSignIn }
