import { Box, Button, Card, CardContent, Stack, Typography, styled, Grid } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom';
import { PreAuthRoutes } from '../../../../routes';
import { URL_BRAND_PORTAL } from '../../../../_helpers';


// Styled header container
const StyledCardContent = styled(({ ...props }) => (<CardContent  {...props} />))`
    padding: 2.5rem !important;
`;


function JoinToday() {

    const history = useHistory();

    /**
     * function for redirect to promos
     * @author Akshay N
     * @created_at 05 June 2022
     */
    const redirectToBrandPortal = () => {
        window.location.href = URL_BRAND_PORTAL;
    }

    /**
     * function for redirect to contact us
     * @author Akshay N
     * @created_at 05 June 2022
     */
    const redirectToContactUs = () => {
        history.push(PreAuthRoutes('contact_us').path);
    }


    return (
        <Box>
            <Card>
                <StyledCardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`}>
                            <Typography variant="h6">Do you want to promote your business with us ?</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`} justifyContent={{ xs: "start", md: "right" }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={redirectToBrandPortal}>Join Today</Button>
                                <Button variant="contained" color='secondary'  onClick={redirectToContactUs}>Contact Us</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </StyledCardContent>
            </Card>
        </Box>
    )
}

export { JoinToday }