import React from 'react'
import { PreSignIn } from '../../../_layouts/pre-signin'
import { PreAuthRoutes } from '../../../routes'
import { Helmet } from 'react-helmet'
import { APP_NAME } from '../../../_helpers'
import { CardHeader } from '../../../_components'
import { Typography } from '@mui/material'

function TermsAndConditions() {
    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('terms_and_conditions').name}`}</title>
            </Helmet>
            <CardHeader title={PreAuthRoutes('terms_and_conditions').name} />
            <Typography variant="body2" paragraph>
                By visiting our site, using the Service, using any of our products and services, participating in an electronic conversation facilitated by our products and services, and/ or purchasing something from us, you engage in the “Service” and agree to be bound by the following terms and conditions (“Terms of Service” or “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply  to all users of the Service, including without limitation users who are browsers, vendors, customers, merchants, consumers and/ or contributors of content.
            </Typography>
            <Typography variant="body2" paragraph>
                Please read these Terms of Service carefully before accessing or using the Service. By accessing or using any part of the Service, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
            </Typography>
            <Typography variant="body2" paragraph>
                Any new features or tools which are added to the current website shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Service following the posting of any changes constitutes acceptance of those changes.
            </Typography>
            <Typography variant="body2" paragraph>
                By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
            </Typography>
            <Typography variant="body2" paragraph>
                You may not use our Service for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws, and laws governing communications and marketing, including but not limited to the Telephone Consumer Protection Act, 47 U.S.C. 227).
                You must not transmit any worms or viruses or any code of a destructive nature.
                A breach or violation of any of the Terms will result in an immediate termination of your Services.
            </Typography>
            <Typography variant="body2" paragraph>
                We reserve the right to refuse service to anyone for any reason at any time.
                You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
                The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                The Service is available only to individuals who are at least 18 years old.
            </Typography>
            <Typography variant="body2" paragraph>
                We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
            </Typography>
            <Typography variant="body2" paragraph>
                Prices for our products are subject to change without notice.
                We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
            </Typography>
            <Typography variant="body2" paragraph>
                Products or Services are available exclusively online through the website. These products or Services may have limited quantities and are subject to refunds only according to our Refund Policy.
                We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
            </Typography>
            <Typography variant="body2" paragraph>
                We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
            </Typography>
            <Typography variant="body2" paragraph>
                Our charges for monthly plans are posted on our website and may be changed from time to time. If any part of a month is included in the Term, then payment is due for the full month. Payments are due for any month on the same or closest date to the day you made your first monthly payment (the “Pay Date”). If you go over your sending limit and reach another pricing level, then you’ll have to pay at the higher level on or before the next pay date. If the Term ends before that payment is due, you’ll still be required to make one payment at the higher level
                If you have signed up for a Ad Run Digital subscription, you will provide us with valid credit card information and hereby authorize us to deduct the monthly charges against that credit card. You’ll replace the information for any credit card that expires with information for a valid one. Anyone using a credit card represents and warrants that they are authorized to use that credit card, and that any and all charges may be billed to that credit card and won’t be rejected. If we’re unable to process your credit card order, we’ll try to contact you by email and suspend your account until your payment can be processed.
                We’ll give you a refund for a prepaid month if we stop providing our Services to you for a reason that’s not laid out in these Terms. You won’t be entitled to a refund from us under any other circumstances. We may offer a refund if a Member applies for one based on the requirements posted on the Website.
                We may change our fees at any time by posting a new pricing structure to our website and/or sending you a notification by email.
                The Service may include certain features that enable you to sell goods, products, content, media and services (your “User Products”) through in-bot payments.
                You are solely responsible for the content of your communications with your end users via the Service, any User Products you may provide through your use of the Service, any payments related activities, and any promotions and related content contained or referred to in your bot or through your use of the Service, and compliance with any laws applicable thereto. We are merely providing the platform for you to manage your such activities. We are not involved in and disclaim any responsibility for your User Products, your relationship with and/or any transaction with any actual or potential end user of your User Products. When someone purchases your User Products, such transaction’s payments will be processed through a third-party payment service, in accordance with such service provider’s terms of service and other applicable policies. We are neither a party to nor are responsible in any way for your relationship with payment service providers, or for the actions of any of these payment service providers.
                By using any of our payments features, you acknowledge, warrant and agree that:
                You shall be solely and fully responsible for all taxes and fees of any nature associated with your payments activities, including any taxes related to the purchase or sale of the User Products, and to collect, report and remit the correct amounts to the appropriate authorities and/or inform your end users of such and provide them with a duly issued invoice as required by law;
                You shall be responsible for and bear all costs of procuring and delivering your User Products, and for providing them in a safe and professional manner, consistent with industry standards;
                You are solely responsible for any and all statements and promises you make and for all assistance, warranty and support regarding the User Products, and shall provide true contact information on your bot for any questions, complaints or claims
                You are solely responsible for verifying the identity of users and of the eligibility of a presented payment card used to purchase your User Products, products and services, and Ad Run Digital does not guarantee or assume any liability for transactions authorized and completed which may later be reversed or charged back. You are solely responsible for all reversed or charged back transactions, regardless of the reason for, or timing of, the reversal or chargeback. Ad Run Digital may add or remove one or more types of cards as a supported payment card any time without prior notice to you.
                You and you solely, are responsible for providing service to your customers for any and all issues related to your products and services, including but not limited to issues arising from the processing of customers’ cards through the Service.
                You are fully responsible for the security of data on your website or otherwise in your possession. You agree to comply with all applicable state and federal laws and rules in connection with your collection, security and dissemination of any personal, financial, card, or transaction information on your website.
            </Typography>
            <Typography variant="body2" paragraph>
                You represent and warrant that your use of any part of the Service will comply with all applicable laws and regulations. You are responsible for determining whether our Services are suitable for you to use in light of any applicable regulations such as EU Data Privacy Laws or other laws. If you’re subject to regulations (such as HIPAA) and you use our Service, then we won’t be liable if our Service doesn’t meet those requirements. You may not use our Service for any unlawful or discriminatory activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, or other laws that apply to commerce. You are also responsible to notify the end-user of data collection in connection with the Software, as required by applicable law.
            </Typography>
            <Typography variant="body2" paragraph>
                Your submission of personal information through the Service is governed by our Privacy Policy.
            </Typography>
            <Typography variant="body2" paragraph>
                Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, services and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                We undertake no obligation to update, amend, or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
            </Typography>
            <Typography variant="body2" paragraph>
                In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content, or the Service: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet; (l) in any way which violates the Facebook platform policies found here. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
                You also agree not to misuse our Services, for example, by interfering with them or accessing them using a method other than the interface and the instructions that we provide. You may not do any of the following while accessing or using the Services: (1) access, tamper with, or use non-public areas of the Service or Ad Run Digital’s computer systems; (2) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (3) access or search or attempt to access or search the Service by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by Ad Run Digital and third-party integrations authorized by us (and only pursuant to the applicable terms and conditions), unless you have been specifically allowed to do so in a separate agreement with Ad Run Digital (NOTE: crawling the Service is permissible if done in accordance with the provisions of the robots.txt file and is limited to publicly-accessible parts of the Service only, however, scraping the Services without the prior consent of Ad Run Digital is expressly prohibited); (4) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or (5) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Service, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Service.
                We preserve the right to limit access to the platform for users and/or Facebook pages whose actions result in degrading the experience of other platform users, other Facebook users, Facebook’s employees, affiliates, or partners, or Ad Run Digital’s employees, affiliates, or partners.
                We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (1) satisfy any applicable law, regulation, legal process or governmental request, (2) enforce the Terms, including investigation of potential violations hereof, (3) detect, prevent, or otherwise address fraud, security or technical issues, (4) respond to user support requests, or (5) protect the rights, property or safety of Ad Run Digital, its users and the public. Ad Run Digital does not disclose personally-identifying information to third parties except in accordance with our Privacy Policy.
            </Typography>
            <Typography variant="body2" paragraph>
                We do not guarantee, represent, or warrant that your use of the Service will be uninterrupted, timely, secure or error-free.
                We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.
                You agree that from time to time we may remove the Service for indefinite periods of time or cancel the service at any time, without notice to you.
                You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and services delivered to you through the Service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                In no case shall Ad Run Digital, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
            </Typography>
            <Typography variant="body2" paragraph>
                You agree to indemnify, defend and hold harmless Ad Run Digital and any parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
            </Typography>
            <Typography variant="body2" paragraph>
                In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
            </Typography>
            <Typography variant="body2" paragraph>
                In the event these Terms are terminated, the obligations and liabilities of the parties incurred prior to such date shall survive the termination of these Terms for all purposes.
                These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our Service.
                If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
            </Typography>
            <Typography variant="body2" paragraph>
                The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
            </Typography>
            <Typography variant="body2" paragraph>
                You verify that any contact information provided to Ad Run Digital and its clients, including, but not limited to, your e-mail address and mobile phone number is true and accurate. You further verify that you are the telephone or email subscriber and/or that you own any telephone numbers and/or email addresses that you provide to Ad Run Digital and its clients. You acknowledge that by voluntarily providing your telephone numbers and/or email addresses to Ad Run Digital and its clients, you expressly agree to be contacted at the telephone numbers and/or email addresses you provide. You consent to receive e-mails, text messages, pre-recorded voice messages and/or autodialed calls by or on behalf of Ad Run Digital and its clients relating to this agreement, any transaction with Ad Run Digital and its clients, matters related to your account, and promotions from Ad Run Digital and its clients. These communications may be made by or on behalf of Ad Run Digital and its clients, even if your phone number is registered on any state, provincial, territorial or federal Do Not Call list. You acknowledge that you may incur a charge for these texts or calls by your telephone carrier and that Ad Run Digital and its clients will not be responsible for these charges.
            </Typography>
            <Typography variant="body2" paragraph>
                You can review the most current version of the Terms of Service at any time at this page.
                We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
            </Typography>
        </PreSignIn>
    )
}

export { TermsAndConditions }