import { TestimonialsServices } from "../services";

export const TESTIMONIALS_REQUEST = 'TESTIMONIALS_REQUEST';
export const TESTIMONIALS_SUCCESS = 'TESTIMONIALS_SUCCESS';
export const TESTIMONIALS_FAILURE = 'TESTIMONIALS_FAILURE';

export const TestimonialsAction = {

    /**
     * Action for list promos
     * @param null
     * @author Akshay 
     */
    listTestimonials: () => {
        return dispatch => {
            dispatch(request());
            return TestimonialsServices.listTestimonials()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: TESTIMONIALS_REQUEST } }
        function success(response) { return { type: TESTIMONIALS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: TESTIMONIALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

};
