import { CouponCodesServices } from "../services";

export const COUPON_CODES_REQUEST = 'COUPON_CODES_REQUEST';
export const COUPON_CODES_SUCCESS = 'COUPON_CODES_SUCCESS';
export const COUPON_CODES_FAILURE = 'COUPON_CODES_FAILURE';

export const CouponCodesAction = {

    /**
     * Action for list coupon_codes
     * @param null
     * @author Akshay 
     */
    listCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CouponCodesServices.listCouponCodes(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: COUPON_CODES_REQUEST, request } }
        function success(request, response) { return { type: COUPON_CODES_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: COUPON_CODES_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
}