import { PromoServices } from "../services";

export const FEATURED_PROMOS_REQUEST = 'FEATURED_PROMOS_REQUEST';
export const FEATURED_PROMOS_SUCCESS = 'FEATURED_PROMOS_SUCCESS';
export const FEATURED_PROMOS_FAILURE = 'FEATURED_PROMOS_FAILURE';

export const PROMOS_REQUEST = 'PROMOS_REQUEST';
export const PROMOS_SUCCESS = 'PROMOS_SUCCESS';
export const PROMOS_FAILURE = 'PROMOS_FAILURE';

export const PROMO_DETAIL_REQUEST = 'PROMO_DETAIL_REQUEST';
export const PROMO_DETAIL_SUCCESS = 'PROMO_DETAIL_SUCCESS';
export const PROMO_DETAIL_FAILURE = 'PROMO_DETAIL_FAILURE';

export const PROMO_INFO_REQUEST = 'PROMO_INFO_REQUEST';
export const PROMO_INFO_SUCCESS = 'PROMO_INFO_SUCCESS';
export const PROMO_INFO_FAILURE = 'PROMOINFOL_FAILURE';

export const PromoAction = {

    /**
     * Action for list promos
     * @param null
     * @author Akshay 
     */
    listFeaturedPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listFeaturedPromos(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: FEATURED_PROMOS_REQUEST } }
        function success(response) { return { type: FEATURED_PROMOS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: FEATURED_PROMOS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for list promos
     * @param null
     * @author Akshay 
     */
    listPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listPromos(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: PROMOS_REQUEST } }
        function success(response) { return { type: PROMOS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: PROMOS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for get promo details
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getPromoDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.getPromoDetails(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_DETAIL_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_DETAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_DETAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for get promo details
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getPromoInfo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.getPromoInfo(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_INFO_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_INFO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_INFO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

};
