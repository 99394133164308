import { Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { PreAuthRoutes } from '../../../routes'
import { CardHeader } from '../../../_components'
import { APP_NAME } from '../../../_helpers'
import { PreSignIn } from '../../../_layouts/pre-signin'

function RefundPolicy() {
    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('refund_policy').name}`}</title>
            </Helmet>
            <CardHeader title='No Refund/Cancellation Policy for Self-Pay Services' />
            <Typography variant="body2" paragraph>
                Once paid, Installation fees and plan amounts are non-refundable. If a brand desires to use Adrun for an earlier or later date, they are eligible to use the system with the plan they have purchased already. 
            </Typography>
            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                Plan Upgradation 
            </Typography>
            <Typography variant="body2" paragraph>
                If the brand has chosen a plan and they wish to upgrade it, they must have to purchase the required plan separately with the cost mentioned in the plan section. No plan upgrade options are provided by Adrun digital. 
            </Typography>
            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                Registration Confirmations 
            </Typography>
            <Typography variant="body2" paragraph>
                We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </Typography>
            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                Payment Information 
            </Typography>
            <Typography variant="body2" paragraph>
                Adrun Digitals accepts all major credit and debit cards, and bank transfers . A payment charged to your credit card account is processed in Indian funds at the then applicable foreign currency exchange rate if the price shown is denominated in a currency other than INR (₹). We are not responsible for any discrepancies between bank exchange rates, bank card exchange rates, Internet posted exchange rates, etc.  If you are unable to pay using your Visa, MasterCard, American Express or Discover credit card please contact our office at +91 81291 77057 to make alternate payment arrangements. 
            </Typography>
        </PreSignIn>
    )
}

export { RefundPolicy }