import { Home, FeaturedPromos, About, ContactUs, TermsAndConditions, PrivacyPolicy, RefundPolicy, FrequentlyAskedQuestions, CompleteProfile, SharedPromoDetails } from "../app/pre-signin";
import { ConfirmEmail } from "../app/pre-signin/confirm-email/ConfirmEmail";
import { SharedGiveawayDetails } from "../app/pre-signin/shared-giveaway-details";

const allRoutes = {
    'home': {
        path: "/",
        name: "Home",
        component: Home,
        layout: "/pre-auth",
        isMainComponent: true,
    },
    'log_in': {
        name: "Log In",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'register': {
        name: "Register",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'confirm_email': {
        path: "/confirm-email/:key",
        name: "Confirm email address",
        component: ConfirmEmail,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'forgot_password': {
        name: "Forgot Password",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'verify_otp': {
        name: "Verify OTP",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'reset_password': {
        name: "Reset Password",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'promos': {
        path: "/featured-promos",
        name: "Promos",
        component: FeaturedPromos,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'about_us': {
        path: "/about-us",
        name: "About Us",
        component: About,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'faq': {
        path: "/faq",
        name: "FAQ",
        component: FrequentlyAskedQuestions,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'contact_us': {
        path: "/contact-us",
        name: "Contact Us",
        component: ContactUs,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'terms_and_conditions': {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        component: TermsAndConditions,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'privacy_policy': {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: PrivacyPolicy,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'refund_policy': {
        path: "/refund-policy",
        name: "Refund Policy",
        component: RefundPolicy,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'complete_profile': {
        path: "/complete-profile/:key",
        name: "Complete Profile",
        component: CompleteProfile,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'shared_promo': {
        path: "/share/promo/:id",
        name: "Promo Details",
        component: SharedPromoDetails,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'shared_giveaway': {
        path: "/share/giveaway/:id",
        name: "Giveaway Details",
        component: SharedGiveawayDetails,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
};


function PreAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PreAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PreAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PreAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}

export { PreAuthRoutes, PreAuthMenuRoutes, PreAuthModuleMenuRoutes, PreAuthMainComponents, getComponentNameByPath };