import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box } from '@mui/material';
import { FormInput, SubmitButton, ModalHeader, ModalBody, ModalFooter } from '../../../../_components';
import { handleInputChange } from '../../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../../_helpers/form/validateForm';
import { validate } from '.';
import { PreAuthRoutes } from '../../../../routes';
import { ForgotPasswordAction } from '../../../../redux/actions';

// Initialize form input values to null
const inputs = { otp: '', email: '' };

function VerifyOtp({ backTo, onClose, forgotPasswordData }) {

    const dispatch = useDispatch();

    /** Redux actions and state */
    const { pwd_rst_verify_otp_loading: isLoading, pwd_rst_verify_otp_errors: pwdRstVerifyOtpErrors } = useSelector((state) => state.ForgotPasswordReducer);
    const verifyOtp = (params) => dispatch(ForgotPasswordAction.verifyOtp(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...pwdRstVerifyOtpErrors });
    }, [pwdRstVerifyOtpErrors])

    useEffect(() => {
        setData({ ...data, email: forgotPasswordData.email });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPasswordData])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSendPasswordResetLinkAction();
    }

    /**
     * function to dispatch send password reset link
     * @author Akshay N
     */
    async function dispatchSendPasswordResetLinkAction() {
        const isOtpVerified = await verifyOtp(data);
        if (isOtpVerified.status === 1) {
            backTo('reset_password', data)
        }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <ModalHeader textStyle={{ textAlign: `center`, width: `100%` }} id="auth-modal" title={PreAuthRoutes(`verify_otp`).name} onClose={onClose} />
                <ModalBody>
                    <Box>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='OTP'
                                    name='otp'
                                    value={data.otp}
                                    error={action.isSubmitted && errors.otp ? errors.otp : ''}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter sx={{ justifyContent: `center`, width: `100%` }}>
                    <Box>
                        <Stack direction="column" display={`flex`} alignItems="center" spacing={1} sx={{ width: `100%` }} >
                            <SubmitButton label="Verify" color={`secondary`} fullWidth loading={isLoading} />
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
        </React.Fragment>
    );
}

export { VerifyOtp };
