import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getComponentNameByPath, PreAuthMenuRoutes, PreAuthRoutes } from '../../../routes';
import { AppNameContext } from '../../../_contexts/AppNameContext';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, styled, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material'
import { RouteLink, Logo } from '../../../_components';
import { Auth } from '..';

// Styled header container
const StyledHeaderContainer = styled(({ ...props }) => (<Container maxWidth="xl"  {...props} />))`
    padding-top: 15px;
    border-bottom: 1px solid ${props => props.theme.palette.dark.main};;
    margin-bottom: 30px;
`;

// Styled header logo
const StyledHeaderLogo = styled(({ ...props }) => (<Logo {...props} />))`
    margin-right:8px;
    ${props => props.theme.breakpoints.down("md")} {
        height: 15px;
    }
`;

function Header() {

    /** Initialize hooks, plugins and constants */
    const location = useLocation();
    const appName = useContext(AppNameContext);
    const menuRoutes = Object.values(PreAuthMenuRoutes());

    /** Initialize and declare state */
    const [anchorElNav, setAnchorElNav] = useState(false);
    const [pageTitle, setPageTitle] = useState('Loading')


    /**
     * function to open the navigation menu bar
     * @param event
     * @return view
     * @author Akshay N
     * @created_at 05 June 2022
     */
    const handleOpenOrCloseNavMenu = (event) => {
        setAnchorElNav(anchorElNav ? false : event.currentTarget);
    };


    useEffect(() => {
        setPageTitle(getComponentNameByPath(location.pathname));
    }, [location])

    return (
        <React.Fragment>

            <Helmet>
                <title>{`${appName} | ${pageTitle}`}</title>
            </Helmet>

            <AppBar position="static" >
                <StyledHeaderContainer>
                    <Toolbar disableGutters>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenOrCloseNavMenu}><MenuIcon /></IconButton>
                            <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(anchorElNav)} onClose={handleOpenOrCloseNavMenu} sx={{ display: { xs: 'block', md: 'none' } }} >
                                {menuRoutes.map((route, index) => {
                                    var splitPathName = location.pathname.split('/');
                                    var parentPathName = splitPathName.slice(0, 2).join('/');
                                    return (<RouteLink key={index} to={route.path} label={<MenuItem className={(route.path === location.pathname) ? `menu-active` : ((parentPathName === route.path) ? 'menu-active' : '')}>{route.name}</MenuItem>} />)
                                })}
                            </Menu>
                        </Box>
                        <RouteLink key={PreAuthRoutes('home').name} to={PreAuthRoutes('home').path} label={<StyledHeaderLogo />} />
                        <Box sx={{ flexGrow: 1, display: { md: 'none' } }} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {menuRoutes.map((route, index) => {
                                var splitPathName = location.pathname.split('/');
                                var parentPathName = splitPathName.slice(0, 2).join('/');
                                return (<RouteLink key={index} to={route.path} label={<Button className={(route.path === location.pathname) ? `menu-active` : ((parentPathName === route.path) ? 'menu-active' : '')}>{route.name}</Button>} />)
                            })}
                        </Box>
                        <Box>
                            <Stack direction="row" spacing={2}><Auth /></Stack>
                        </Box>
                    </Toolbar>
                </StyledHeaderContainer>
            </AppBar>
        </React.Fragment>
    );
}

export { Header };
