import { makeStyles } from "@mui/styles";
import colors from "../../../_theme/theme-dark/base/colors";

const useStyles = makeStyles({
	textWrongTurn: {
		color: colors.primary.main,
		marginLeft: `15px`
	},
	textNotFound: {
		color: colors.error.main
	}
})

export { useStyles };
