import React from 'react';
import { Box, Card, CardContent, CardHeader, CardMedia, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Typography } from '../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalAction } from '../../../redux/actions';
import { PostAuthRoutes } from '../../../routes';
import { useHistory } from 'react-router-dom';
import { POST_BROKEN_IMAGE } from '../../../_helpers';


const GiveawayPaper = styled(({ ...props }) => (<Card {...props} />))`
        padding: 0;
        cursor: pointer;

        & .MuiCardContent-root {
          border-radius: 0.75rem;
        }
        & .MuiSvgIcon-root {
            color: ${props => props.theme.palette.secondary.main};
            background: rgba(255, 255, 255, 0.64);
            border-radius: 50%;
            padding: 3px;
        }
        & .MuiTypography-root {
            color: ${props => props.theme.palette.white.main};
        },
` ;

function GiveawayCard({ ...props }) {


    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { loggedIn: isLoggedIn } = useSelector((state) => state.SignInReducer);

    const showAuthDialog = (isDialogOpen) => dispatch(GlobalAction.showAuthDialog(isDialogOpen));

    const { _id, name, description, insta_handle, sample_story } = props;

    function cardClick() {
        if (isLoggedIn) {
            let redirectPath = `${PostAuthRoutes('giveaway').path}?gId=${_id}`
            history.push(redirectPath);
        } else {
            showAuthDialog(true);
        }
    }

    return (
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}  {...props}>
            <GiveawayPaper {...props} onClick={cardClick}  >
                <CardMedia style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} component="img" image={sample_story} onError={e => { e.target.src = POST_BROKEN_IMAGE }}/>
                <CardContent style={{ position: "absolute", height: `100%`, top: 0, right: 0, left: 0, bottom: 0, backgroundImage: "linear-gradient(360deg, rgba(0, 0, 0, 0.8) 40%, rgba(51, 51, 53, 0) 100%)" }}>
                    <CardHeader />
                    <Box style={{ position: `absolute`, bottom: 0, paddingBottom: 30, paddingRight: 15 }}>
                        <Typography variant={`h5`}>{name}</Typography>
                        <Box style={{ minHeight: `134px` }}>
                            <Typography variant={`subtitle2`} sx={{ fontWeight: 500 }}>{description}</Typography>
                        </Box>
                        <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`Mention @${insta_handle}`}</Typography>
                    </Box>

                </CardContent>
            </GiveawayPaper>
        </Grid>
    );
}

export { GiveawayCard };
