import React from 'react'
import { Link, styled } from '@mui/material'

const StyledLink = styled(({ ...props }) => (<Link {...props} />))`
   & .MuiButton-root{
    color: ${props => props.theme.palette.text.main};
    &:hover{
     color: ${props => props.theme.palette.text.focus};
    }
   }
`;


function AnchorLink({ href, label, ...props }) {
    return (
        <StyledLink href={href} underline={'none'}  {...props}>{label}</StyledLink>
    )
}

export { AnchorLink }
