import { GiveawayServices } from "../services";

export const FEATURED_GIVEAWAYS_REQUEST = 'FEATURED_GIVEAWAYS_REQUEST';
export const FEATURED_GIVEAWAYS_SUCCESS = 'FEATURED_GIVEAWAYS_SUCCESS';
export const FEATURED_GIVEAWAYS_FAILURE = 'FEATURED_GIVEAWAYS_FAILURE';

export const GIVEAWAYS_REQUEST = 'GIVEAWAYS_REQUEST';
export const GIVEAWAYS_SUCCESS = 'GIVEAWAYS_SUCCESS';
export const GIVEAWAYS_FAILURE = 'GIVEAWAYS_FAILURE';

export const GIVEAWAY_DETAIL_REQUEST = 'GIVEAWAY_DETAIL_REQUEST';
export const GIVEAWAY_DETAIL_SUCCESS = 'GIVEAWAY_DETAIL_SUCCESS';
export const GIVEAWAY_DETAIL_FAILURE = 'GIVEAWAY_DETAIL_FAILURE';

export const GIVEAWAY_INFO_REQUEST = 'GIVEAWAY_INFO_REQUEST';
export const GIVEAWAY_INFO_SUCCESS = 'GIVEAWAY_INFO_SUCCESS';
export const GIVEAWAY_INFO_FAILURE = 'GIVEAWAY_INFO_FAILURE';

export const  GiveawayAction = {

    /**
     * Action for list giveaways
     * @param null
     * @author Akshay 
     */
    listFeaturedGiveaways: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.listFeaturedGiveaways(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: FEATURED_GIVEAWAYS_REQUEST } }
        function success(response) { return { type: FEATURED_GIVEAWAYS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: FEATURED_GIVEAWAYS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for list giveaways
     * @param null
     * @author Akshay 
     */
    listGiveaways: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.listGiveaways(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: GIVEAWAYS_REQUEST } }
        function success(response) { return { type: GIVEAWAYS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GIVEAWAYS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for get promo details
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getGiveawayDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.getGiveawayDetails(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_DETAIL_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_DETAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_DETAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for get promo details
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getGiveawayInfo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return GiveawayServices.getGiveawayInfo(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_INFO_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_INFO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_INFO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
