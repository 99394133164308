import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component';

import { PromoAction } from '../../redux/actions';
import { PromoCard } from '../promos';
import { Grid, Stack } from '@mui/material';
import { HomeBanner } from '../banner';
import { PromoCardLoader } from './promo-card';
import { CardHeader, FormSearchInput, NoData } from '../../_components';
import { PostAuthRoutes } from '../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function Promos() {


    // const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { promos_loading: isLoading, promos } = useSelector((state) => state.PromoReducer);
    const listBrandPromos = (params) => dispatch(PromoAction.listPromos(params));


    const [payload, setPayload] = useState({
        limit: 100, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
    });

    useEffect(() => {
        /* Read the payload and append the parameters to the url for proper reload */
        const queryParam = {};
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;

        history.push({
            pathname: PostAuthRoutes('promos').path,
            search: `?${queryString.stringify(queryParam)}`
        });

        getNext()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.search])

    const getNext = async () => {
        const result = await listBrandPromos(payload);
        if (result.status) {
            setPayload({ ...payload });
        }
    }

    /**
     * function to apply the search key words on the promo list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }


    return (
        <React.Fragment>
            <Stack spacing={3}>
                <HomeBanner />
                <CardHeader
                    title={PostAuthRoutes('promos').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`} >
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </Stack>
                    } />
                <InfiniteScroll dataLength={promos.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            promos.map((promo, index) => {
                                return <PromoCard key={index} promo={promo} />
                            })
                        }
                        {isLoading && <PromoCardLoader />}
                        {!isLoading && promos.length === 0 && <NoData content1={`No`} content2={`Promos available`} description={`Posts will available soon.`} minHeight={`55vh`} />}
                    </Grid>
                </InfiniteScroll>
            </Stack>
        </React.Fragment>
    );
}

export { Promos }
