import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Grid, Stack } from '@mui/material'
import { FormPassword } from '../../../../../_components/form'
import { GeneralButton, SubmitButton, CardHeader } from '../../../../../_components'

import { validate } from './validate'
import { handleInputChange, validateForm } from '../../../../../_helpers'
import { UserAction } from '../../../../../redux/actions'
import { PostAuthRoutes, PreAuthRoutes } from '../../../../../routes'
import { SweetAlert } from '../../../../../_components'

// Initialize form input values to null
const inputs = { current_password: '', new_password: '', confirm_password: '' };

function ChangePassword({ cancelButton }) {


    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { change_password_loading: isLoading, change_password_errors: changePasswordErrors } = useSelector((state) => state.UserReducer);
    const changePassword = (params) => dispatch(UserAction.changePassword(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });


    useEffect(() => {
        setErrors({ ...changePasswordErrors });
    }, [changePasswordErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchChangePasswordAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     */
    async function dispatchChangePasswordAction() {
        const isUpdated = await changePassword(data);
        if (isUpdated.status === 1) {
            let timerInterval;
            SweetAlert.fire({
                title: 'Password Updated!',
                html: "Your account password has been updated.You will be redirected soon!<br/><br/>I will close in <b></b> milliseconds.Please login again.",
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    SweetAlert.showLoading()
                    const b = SweetAlert.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft() }, 200)
                },
                willClose: () => {
                    setTimeout(() => {

                        history.push({
                            pathname: PreAuthRoutes('home').path
                        });

                    }, 2000);
                    clearInterval(timerInterval)
                }
            })
        }
    }

    const cancelChangePassword = () => (event) => {
        cancelButton(event);
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CardHeader title={PostAuthRoutes('change_password').name} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormPassword
                            tabIndex={1}
                            label='Current password'
                            name='current_password'
                            value={data.current_password}
                            error={action.isSubmitted && errors.current_password ? errors.current_password : ''}
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormPassword
                            tabIndex={1}
                            label='New password'
                            name='new_password'
                            value={data.new_password}
                            error={action.isSubmitted && errors.new_password ? errors.new_password : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormPassword
                            tabIndex={1}
                            label='Re-enter password'
                            name='confirm_password'
                            value={data.confirm_password}
                            error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack direction={`row`} spacing={1}>
                            <SubmitButton label={'Change'} color={`error`} fullWidth loading={isLoading} />
                            <GeneralButton variant='contained' color={`secondary`} label={`Cancel`} fullWidth onClick={cancelChangePassword()} />
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}
export { ChangePassword };
