import { GlobalAction } from ".";
import { NotificationServices } from "../services";

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_NOTIFICATIONS_LIST_REQUEST = 'GET_NOTIFICATIONS_LIST_REQUEST';
export const GET_NOTIFICATIONS_LIST_SUCCESS = 'GET_NOTIFICATIONS_LIST_SUCCESS';
export const GET_NOTIFICATIONS_LIST_FAILURE = 'GET_NOTIFICATIONS_LIST_FAILURE';

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST = 'MARK_ALL_NOTIFICATIONS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_FAILURE = 'MARK_ALL_NOTIFICATIONS_READ_FAILURE';

export const MARK_NOTIFICATION_READ_REQUEST = 'MARK_NOTIFICATION_READ_REQUEST';
export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAILURE = 'MARK_NOTIFICATION_READ_FAILURE';

export const NotificationAction = {

    getNotifications: (params) => {
        return dispatch => {
            dispatch(request(params));
            return NotificationServices.getNotificationList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_NOTIFICATIONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_NOTIFICATIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_NOTIFICATIONS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    getNotificationList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return NotificationServices.getNotificationList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_NOTIFICATIONS_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: GET_NOTIFICATIONS_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_NOTIFICATIONS_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to mark all notifications as read
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @author Jasin 
     */
    markAllRead: () => {
        return dispatch => {
            dispatch(request());
            return NotificationServices.markAllRead()
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: MARK_ALL_NOTIFICATIONS_READ_REQUEST } }
        function success(response) { return { type: MARK_ALL_NOTIFICATIONS_READ_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: MARK_ALL_NOTIFICATIONS_READ_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to mark notification as read
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @author Jasin 
     */
    markRead: (params) => {
        return dispatch => {
            dispatch(request(params));
            return NotificationServices.markRead(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: MARK_NOTIFICATION_READ_REQUEST, request: request } }
        function success(request, response) { return { type: MARK_NOTIFICATION_READ_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: MARK_NOTIFICATION_READ_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
};





