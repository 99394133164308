import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { PreAuthRoutes } from '../../../routes/PreAuthRoutes';
import { RouteLink } from '../../../_components/controls/route-link';
import { Menu as MenuIcon } from '@mui/icons-material'
import { getPostComponentNameByPath, PostAuthMenuRoutes } from '../../../routes';
import { useLocation } from 'react-router-dom';
import { AppNameContext } from '../../../_contexts/AppNameContext';
import { Helmet } from 'react-helmet';
import { AccountMenu, ThemeMode, Notifications } from '.';
import { useDispatch } from 'react-redux';
import { UserAction } from '../../../redux/actions';
import { Logo } from '../../../_components/logo';
import { styled } from '@mui/system';

const menuRoutes = Object.values(PostAuthMenuRoutes());


const ActiobBox = styled(({ ...props }) => (<Box sx={{ display: { xs: 'none', md: 'flex' } }} {...props} />))`
        & .MuiSvgIcon-root {
            color: ${props => props.theme.palette.text.main};
        }
` ;


// Styled header container
const StyledHeaderContainer = styled(({ ...props }) => (<Container maxWidth="xl"  {...props} />))`
    padding-top: 15px;
    border-bottom: 1px solid ${props => props.theme.palette.dark.main};;
    margin-bottom: 30px;
`;

function Header() {

    /** Declare hooks and plugins and constants */
    const location = useLocation();
    const appName = useContext(AppNameContext);
    const dispatch = useDispatch();

    /** Initialize and declare state */
    const [anchorElNav, setAnchorElNav] = useState(false);
    const [pageTitle, setPageTitle] = useState('Loading')


    useEffect(() => {
        setPageTitle(getPostComponentNameByPath(location.pathname));
    }, [location])

    useEffect(() => {
        dispatch(UserAction.getUserDetails())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${appName} | ${pageTitle}`}</title>
            </Helmet>
            <AppBar position="static" >
                <StyledHeaderContainer>
                    <Toolbar disableGutters>
                        <RouteLink key={PreAuthRoutes('home').name} to={PreAuthRoutes('home').path} label={<Logo sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }} />} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit"  > <MenuIcon /></IconButton>
                            <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} sx={{ display: { xs: 'block', md: 'none' }, }} >
                                {menuRoutes.map((route, index) => {
                                    return <MenuItem key={index} onClick={handleCloseNavMenu}>
                                        <RouteLink key={route.name} to={route.path} label={<Button>{route.name}</Button>} />
                                    </MenuItem>
                                })}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {menuRoutes.map((route, index) => {

                                var splitPathName = location.pathname.split('/');
                                var parentPathName = splitPathName.slice(0, 2).join('/');

                                return (
                                    <RouteLink key={index} to={route.path} label={<Button className={(route.path === location.pathname) ? `menu-active` : ((parentPathName === route.path) ? 'menu-active' : '')}>{route.name}</Button>} />
                                )
                            })}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <ActiobBox >
                            <ThemeMode />
                            <Notifications />
                            <AccountMenu />
                        </ActiobBox>
                    </Toolbar>
                </StyledHeaderContainer>
            </AppBar>
        </React.Fragment>
    );
}

export { Header };
