import { Card, CardContent, CardMedia, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PromoAction } from '../../../redux/actions';
import { NoData } from '../../../_components/layout';
import { PromoDetailsLoader } from '.'
import { POST_BROKEN_IMAGE } from '../../../_helpers';


function PromoDetails() {

    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const promoId = params.get('pId'); // promo id from url

    /** Redux actions and state */
    const { promo_loading: isLoading, promo, promo_errors } = useSelector((state) => state.PromoReducer);
    const getPromoDetails = (params) => dispatch(PromoAction.getPromoDetails(params));

    useEffect(() => {
        getPromoDetails({ promo_id: promoId });
        //eslint-disable-next-line
    }, [promoId])


    return (
        <React.Fragment>
            {(isLoading || Object.keys(promo).length > 0) &&
                <Card sx={{ padding: 8 }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                            {isLoading && (<PromoDetailsLoader />)}
                            {!isLoading && Object.keys(promo).length > 0 && (
                                <CardContent>
                                    <React.Fragment>
                                        <Box>
                                            <Typography variant={`h5`} sx={{ mb: 1 }}>{promo.name}</Typography>
                                            <Box style={{ minHeight: `60px` }}>
                                                <Typography variant={`subtitle2`} sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>{promo.description}</Typography>
                                            </Box>
                                            <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`Mention @${promo.insta_handle}`}</Typography>
                                        </Box>
                                        <Divider sx={{ mt: 5, mb: 5 }} />
                                        {
                                            <Box>
                                                <Typography variant={`h5`} sx={{ mb: 1 }}>{`How to redeem your offer`}</Typography>
                                                <Typography variant={`subtitle2`} sx={{ fontWeight: 500 }}>{`1. Post a ${promo.promo_type} and mention @${promo.insta_handle} on it.`}</Typography>
                                            </Box>
                                        }
                                    </React.Fragment>
                                </CardContent>
                            )
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                            {isLoading ? (
                                <Skeleton variant="rectangular" width={381} height={678} />
                            ) : (
                                <CardMedia component="img" image={promo.file} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={promo.name} onError={e => { e.target.src = POST_BROKEN_IMAGE }} />
                            )}
                        </Grid>
                    </Grid>
                </Card>
            }
            {!isLoading && Object.keys(promo).length === 0 && promo_errors.type === 'NOT_FOUND' && <NoData content1={`Promo`} content2={`Not Found`} description={`If using a custom view, please go back and choose a proper promo.`} minHeight={`55vh`} > </NoData>}
            {!isLoading && Object.keys(promo).length === 0 && promo_errors.type === 'NOT_AVAILABLE' && <NoData content1={`This Promo is`} content2={`Not Available`} description={`If using a custom view, please go back and choose a proper promo.You dont have the required followers for this promo.`} minHeight={`55vh`} > </NoData>}
        </React.Fragment>
    );
}

export { PromoDetails };
