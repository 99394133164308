import { Box, Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { PreAuthRoutes } from '../../../routes'
import { CardHeader } from '../../../_components'
import { APP_NAME } from '../../../_helpers'
import { PreSignIn } from '../../../_layouts/pre-signin'

function About() {
    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('about_us').name}`}</title>
            </Helmet>
            <CardHeader title={PreAuthRoutes('about_us').name} />
            <Typography variant="body2" paragraph>
                Ad Run Digital  is a new social media marketing system introduced for retailers/brands, and it will reduce the cost of advertising and help to get a maximum reach through social media.
            </Typography>
            <Typography variant="body2" paragraph>
                There may be certain product(s) which hold some offers from a brand(s) / retailer(s) / restaurant(s). After the purchase, what the customer (influencer) wants to do is to make a post on Instagram by tagging the brand/retailer/restaurant. If the story is valid, and after successful completion of the verification process, The influencers will receive a coupon. Depending on the brand, while creating an offer, they can decide when the influencer should receive a coupon after adding a story.
            </Typography>
            <Typography variant="body2" paragraph>
                If the Brand’s offer is in 24 hrs mode, the posted story should hold in the influencer’s Instagram profile for 24 hours. Then the influencer will receive a coupon.
                In an “instant coupon” method, the influencer will receive the coupons instantly, after posting an Instagram story.
            </Typography>
            <Typography variant="body2" paragraph>
                To be a part of Ad Run Digital, the brand, have to register within the system. To register, both the brand and the influencer should have a valid Instagram account which is public. Apart from this, the brand should hold a business Facebook page and Instagram business account, in which both should be linked.
            </Typography>

            <Box pb={40}>  </Box>
        </PreSignIn>
    )
}

export { About }