
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        borderRadius: `50%`,
    },
    label: {
        fontWeight: 600,
        letterSpacing: `0.5px`
    },
    input: {
        width: 'auto',
        borderRadius: `.25rem`,
    },
    info:
    {
        marginLeft: `0!important`,
        fontSize: 10,
        padding: 0,
        fontWeight: `600!important`,
        color: theme.palette.text.main,
    }
}))

export { useStyles };