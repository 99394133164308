import { GlobalAction } from ".";
import { UserServices } from "../services";

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const UserAction = {

    /**
    * Action for get user details
    * @author Akshay 
    */
    getUserDetails: () => {
        return dispatch => {
            dispatch(request());
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.getUserDetails()
                .then(
                    response => {
                        dispatch(dispatch(GlobalAction.pageLoader(false)));
                        dispatch(GlobalAction.showAuthDialog(false));
                        dispatch(dispatch(GlobalAction.setTheme(response.data.is_dark_mode)));
                        return dispatch(success(response));
                    },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: USER_REQUEST } }
        function success(response) { return { type: USER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for delete account
     * @author Akshay 
     */
    updateTheme: () => {
        return dispatch => {
            dispatch(request());
            return UserServices.updateTheme()
                .then(
                    response => { dispatch(dispatch(GlobalAction.setTheme(response.data.is_dark_mode))); return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };
        function request() { return { type: USER_UPDATE_REQUEST } }
        function success(response) { return { type: USER_UPDATE_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_UPDATE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
    /**
     * Action for delete account
     * @author Akshay 
     */
    deleteAccount: () => {
        return dispatch => {
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.deleteAccount()
                .then(
                    response => { dispatch(GlobalAction.pageLoader(false)); return response; },
                    error => { return error; }
                );
        };

    },
    /**
     * Action for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the brand user
     * @param {string} params.new_password - New password the brand user
     * @param {string} params.confirm_password - Password re-entered by the brand user
     * @author Akshay 
     */
    changePassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.changePassword(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    }
};



