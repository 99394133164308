import React from 'react'

import { AccountCircle, CheckCircle } from '@mui/icons-material';
import { IconButton, Drawer, Box, Card, CardContent, Grid, Stack, Typography, Alert, AlertTitle, CardMedia } from '@mui/material';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralButton } from '../../../../_components/controls';
import { SocialShare } from '../../../../_components/social-share';
import { SignInAction } from '../../../../redux/actions';
import { ChangePassword } from './change-password';
import { AccountStatus } from './account-status';
import { AVATAR_BROKEN_IMAGE, PRODUCT_NAME } from '../../../../_helpers';
function AccountMenu() {

    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const signOut = () => dispatch(SignInAction.signOut()).then(() => { history.push(`/`); });

    const [state, setState] = React.useState({ right: false });
    const [actionType, setActionType] = React.useState('default');

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);

    const toggleDrawer = (anchor, open) => (event) => {
        if (open === true) {
            setActionType('default');
        }
        if (actionType === 'default') {

            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            setState({ right: open });
        }

    };

    const changeActionType = (type) => (event) => {
        event.stopPropagation();
        setActionType(type);
    }

    function cancelButton(event) {
        event.stopPropagation();
        setActionType('default');
    }

    return (
        <React.Fragment>
            <IconButton onClick={toggleDrawer('right', true)}>
                <AccountCircle />
            </IconButton>
            {!isLoading &&
                (<Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}  >
                    <Card sx={{ width: 384, height: `100%`, backgroundColor: `inherit` }} role="presentation" onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                        <CardContent sx={{ p: 6, height: `100%`, pb: `48px !important`, display: `flex`, alignItems: `center` }}>
                            {actionType === 'default' && <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} >
                                <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            {(user.insta_profile && user.insta_profile.profile_picture_url) ? <CardMedia component="img" image={user.insta_profile.profile_picture_url} onError={e => { e.target.src = AVATAR_BROKEN_IMAGE }} title={user.short_name} height="150" sx={{ borderRadius: `50%`, margin: `0 !important`, border: `2px solid #fff` }} /> : <CardMedia component="img" image={AVATAR_BROKEN_IMAGE} title={user.short_name} height="150" sx={{ borderRadius: `50%`, margin: `0 !important`, border: `2px solid #fff` }} onError={e => { e.target.src = AVATAR_BROKEN_IMAGE }} />}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h4" align={`center`}>{user.name}</Typography>
                                        {user.insta_handle && <Typography variant="h6" align={`center`}>{`@${user.insta_handle}`}</Typography>}
                                        <Typography variant="h6" align={`center`}>{user.email}&nbsp;{user.email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle size='small' />}</Typography>
                                        <Typography variant="h6" align={`center`}>{user.mobile_number}&nbsp;{user.mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle size='small' />}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {user.insta_profile &&
                                            <Grid container direction="row" justifyContent={`space-between`} alignContent={`center`} spacing={2}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Box sx={{ backgroundColor: 'info.main', width: `100%`, p: 1 }}  >
                                                        <Typography variant="h6" align={`center`}>{user.insta_profile.followers_count}</Typography>
                                                        <Typography variant="body2" align={`center`}>{`Followers`}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container mt={2} spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <GeneralButton sx={{ mb: 1 }} variant='contained' fullWidth={true} label={`Change Password`} onClick={changeActionType('changePassword')} />
                                        <GeneralButton sx={{ mb: 3 }} variant='contained' fullWidth={true} label={`Delete Account`} onClick={changeActionType('deleteAccount')} />
                                        <GeneralButton sx={{ mb: 1 }} variant='contained' color={`secondary`} fullWidth={true} label={`Logout`} onClick={signOut} />
                                    </Grid>
                                </Grid>
                            </Stack>}
                            {actionType === 'changePassword' &&
                                <ChangePassword cancelButton={cancelButton} />
                            }
                            {actionType === 'deleteAccount' &&
                                <AccountStatus cancelButton={cancelButton} />
                            }
                        </CardContent>
                    </Card>
                    <Box sx={{ width: 384, p: 2 }}>
                        <Alert icon={false} sx={{ display: `flex`, alignItems: `center` }}>
                            <AlertTitle sx={{ fontSize: `12px` }}>Refer & Earn</AlertTitle>
                            <Typography variant="body2" sx={{ fontSize: `10px` }}>Invite a friend and receive bonus coupon code when they post their first story.</Typography>
                            <Typography variant="h6" sx={{ mt: `5px`, fontSize: `10px` }} >{user.referral_code}</Typography>
                            <SocialShare url={user.referral_code} title={`Sign up on ${PRODUCT_NAME} using this link and post a promo story to get exclusive offers.`} />
                        </Alert>
                    </Box>
                </Drawer>
                )
            }
        </React.Fragment>
    )
}

export { AccountMenu }
