import React, { useState } from 'react';
import { Container, Grid, Stack, Typography, Button, InputBase, styled, FormHelperText, Box } from '@mui/material'
import { Email, Facebook, Instagram, LocalPhone, LocationOn, Send } from '@mui/icons-material';
import { Logo } from '../../../_components/logo/logo/Logo'
import { useDispatch } from 'react-redux';
import { handleInputChange } from '../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../_helpers/form/validateForm';
import { validate } from '.'
import { RegisterAction } from '../../../redux/actions/RegisterAction';
import { PRODUCT_NAME } from '../../../_helpers';
import { AnchorLink, RouteLink } from '../../../_components';
import { PreAuthRoutes } from '../../../routes';


// Initialize form input values to null
const inputs = { email: '' };

const FooterContainer = styled(({ ...props }) => (<Container maxWidth="sm" component="main" {...props} />))`
    border-top: 1px solid #09304B;
    padding-bottom: 100px;
`;

const FooterGridContainer = styled(({ ...props }) => (<Grid container spacing={3}  {...props} />))`
    padding-top: 50px;
`;

const NewsLetterGrid = styled(({ ...props }) => (<Grid item xs={12} sm={12} md={3} lg={3} xl={3} {...props} />))`
    border-radius: 0.375rem; 
    & .MuiInputBase-root{
        background-color:${props => props.theme.palette.formInput.background};
        border: 10px;
        padding-left: 15px;
        & .MuiButton-root{
            border-radius: 0;
            min-width: 35px;
            padding: 0 1rem;
        }
    }
`;

const SocialMediaGrid = styled(({ ...props }) => (<Grid item xs={12} sm={12} md={3} lg={3} xl={3} {...props} />))`
    & .MuiLink-root {
        background-color:${props => props.theme.palette.formInput.background};
        color:${props => props.theme.palette.text.main} !important;
        border-radius: 0;
        min-width: 35px;
        padding: 0.65rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & .MuiSvgIcon-root {
            ${'' /* font-size: 1.125rem !important; */}
        }
    }
`;

function Footer() {


    /** Declare hooks and plugins and constants */
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const submitNewsLetter = (params) => dispatch(RegisterAction.submitNewsLetter(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle registration form submit action
     * @param {object} e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchNewsLetterAction();
    }

    /**
     * function to dispatch registration api
     * @author Akshay N
     */
    async function dispatchNewsLetterAction() {
        const isSubmited = await submitNewsLetter(data);
        console.log(isSubmited);
        if (isSubmited && isSubmited.status === 1) {
            setData({ ...inputs });
            setErrors({ ...inputs });
            setAction({ isSubmitted: false })
        } else {
            setErrors({ ...isSubmited.error });
            setAction({ isSubmitted: false })
        }
    }

    return (
        <FooterContainer>
            <FooterGridContainer>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                    <Stack direction={`column`} spacing={1} alignItems="flex-start" justifyContent="left">
                        <Logo />
                        <Typography variant="body2">{PRODUCT_NAME} is a revolutionary platform that allows you to check how good your ads run in the social media out there.</Typography>
                        <Box sx={{ display: `flex`, alignItems: `center` }}>
                            <RouteLink to={PreAuthRoutes('terms_and_conditions').path} label={PreAuthRoutes('terms_and_conditions').name} sx={{ fontSize: `12px`, mr: 1, color: `#fff` }} />
                            <RouteLink to={PreAuthRoutes('privacy_policy').path} label={PreAuthRoutes('privacy_policy').name} sx={{ fontSize: `12px`, color: `#fff` }} />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Stack direction={`column`} spacing={1} >
                        <Typography variant="h6">Contact Info</Typography>
                        <Stack direction={`row`} spacing={1} alignItems={`center`}>
                            <LocationOn />
                            <Typography variant="body2">1-48A, Main Road, Neermulal,<br /> Nagapattinam, Tamil Nadu,<br /> India, 614711</Typography>
                        </Stack>
                        <Stack direction={`row`} spacing={1} alignItems={`center`}>
                            <LocalPhone />
                            <Typography variant="body2">+91 81291 77057</Typography>
                        </Stack>
                        <Stack direction={`row`} spacing={1} alignItems={`center`}>
                            <Email />
                            <Typography variant="body2">contact@adrundigital.com</Typography>
                        </Stack>
                    </Stack>

                </Grid>
                <NewsLetterGrid>
                    <form onSubmit={handleSubmit} noValidate>
                        <Stack direction={`column`} spacing={1} >
                            <Typography variant="h6">Newsletter</Typography>
                            <InputBase
                                fullWidth
                                name="email"
                                type="text"
                                value={data.email}
                                placeholder="Email address"
                                onChange={handleChange}
                                endAdornment={<Button type='submit' color='secondary' variant="contained"><Send /></Button>}
                            />
                            <FormHelperText id={`helper_news_letter_email`}>{action.isSubmitted && errors.email ? errors.email : ''}</FormHelperText>
                        </Stack>
                    </form>
                </NewsLetterGrid>
                <SocialMediaGrid>
                    <Stack direction={`column`} spacing={1} >
                        <Typography variant="h6">Connect With</Typography>
                        <Stack direction={`row`} spacing={1} alignItems={`center`}>
                            <AnchorLink label={<Facebook />} href="https://www.facebook.com/adrundigital" target="_blank" />
                            <AnchorLink label={<Instagram />} href="https://www.instagram.com/adrundigital/" target="_blank" />
                        </Stack>
                    </Stack>
                </SocialMediaGrid>
            </FooterGridContainer>
        </FooterContainer>
    )
}

export { Footer }