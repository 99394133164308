import { Box, Card, CardContent, CardMedia, Chip, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoryDetailsLoader } from '../..';
import { StoriesAction } from '../../../../redux/actions';
import { POST_BROKEN_IMAGE } from '../../../../_helpers';
import { CouponCodesCard } from '../../../coupon-codes';

function StoryDetails() {

    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const storyId = params.get('sId'); // promo id from url

    /** Redux actions and state */
    const { story_loading: isLoading, story } = useSelector((state) => state.StoriesReducer);
    const getStoryDetails = (params) => dispatch(StoriesAction.getStoryDetails(params));

    useEffect(() => {
        getStoryDetails({ _id: storyId });
        //eslint-disable-next-line
    }, [storyId])

    return (
        <Card sx={{ padding: 6, width: '100%' }}>
            {isLoading ? (
                <StoryDetailsLoader />
            ) : (
                <Grid container style={{ minHeight: `330px` }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display="flex" alignItems="center"  >
                        <CardMedia component="img" image={story.story_url} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={story.ig_id} onError={e => { e.target.src = POST_BROKEN_IMAGE }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                        <CardContent style={{ width: `100%` }} >
                            <Box>
                                <Typography variant={`body2`} sx={{ fontWeight: 500 }}>{`Posted on - ${story.created_at}`}</Typography>
                                <Typography variant={`body2`} sx={{ fontWeight: 500 }}>{`Status - ${story.status.toUpperCase()}`}</Typography>
                                <Divider />
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CouponCodesCard height='130px' status={story.status} promo_name={null} promo_id={storyId} coupon={story.coupon} isClickable={false} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: `100%`, mt: 1 }} >
                                            <Box sx={{ width: `${story.current_checkpoint ? story.current_checkpoint.percentage : 0}%`, mr: 1 }}>
                                                <LinearProgress variant="determinate" value={story.current_checkpoint ? story.current_checkpoint.percentage : 0} />
                                            </Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">{`${story.current_checkpoint ? story.current_checkpoint.percentage : 0}%`}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ pb: 0, mb: 2 }} />
                                {
                                    story.tags.map((tag, i) => {
                                        return <Chip key={i} label={`#${tag}`} color="primary" style={{ marginRight: 3, borderRadius: 4 }} />
                                    })
                                }
                            </Box>
                        </CardContent>
                    </Grid>
                </Grid>
            )}
        </Card >
    )
}

export { StoryDetails }