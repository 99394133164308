import { FaqServices } from "../services";

export const GET_FAQ_LIST_REQUEST = 'GET_FAQ_LIST_REQUEST';
export const GET_FAQ_LIST_SUCCESS = 'GET_FAQ_LIST_SUCCESS';
export const GET_FAQ_LIST_FAILURE = 'GET_FAQ_LIST_FAILURE';

export const FaqAction = {

    getFaqList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FaqServices.getFaqList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_FAQ_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: GET_FAQ_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_FAQ_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
};