import { apiServices } from '../../_helpers';

export const BrandServices = {

    /**
     * Service to get notification list
     * @author Jasin 
     */
    getBrandList: (params) => {
        return apiServices.post(`/influencer/brand/list`, params)
            .then((response) => { return response; })
    },

};
