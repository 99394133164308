export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_REQUEST_TIMEOUT = 10000;

export const URL_PRIVACY_POLICY = 'https://google.com';
export const URL_TERMS_OF_SERVICE = 'https://google.com';

export const URL_BRAND_PORTAL = process.env.REACT_APP_URL_BRAND_PORTAL ? process.env.REACT_APP_URL_BRAND_PORTAL : 'https://manage.adrundigital.com/';

export const POST_BROKEN_IMAGE = process.env.REACT_APP_POST_BROKEN_IMAGE;
export const AVATAR_BROKEN_IMAGE = process.env.REACT_APP_AVATAR_BROKEN_IMAGE;


export const DRAWER_WIDTH = 240;

export const IG_APP_ID = process.env.REACT_APP_IG_APP_ID;
export const IG_APP_REDIRECT_URI = process.env.REACT_APP_IG_APP_REDIRECT_URI;