import { Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PromoAction } from '../../../redux/actions';
import { PreSignIn } from '../../../_layouts/pre-signin'
import { PromoCard, PromoCardLoader } from '../../promos';
import { CardHeader, FormSearchInput, NoData } from '../../../_components';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { PreAuthRoutes } from '../../../routes';
import { Banner } from '../home';

function FeaturedPromos() {

    // const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { featured_promos_loading: isLoading, featured_promos } = useSelector((state) => state.PromoReducer);
    const listFeaturedPromos = (params) => dispatch(PromoAction.listFeaturedPromos(params));

    const [payload, setPayload] = useState({
        limit: 100, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
    });

    const getNext = async () => {
        const result = await listFeaturedPromos(payload);
        if (result.status) {
            setPayload({ ...payload });
        }
    }


    useEffect(() => {

        /* Read the payload and append the parameters to the url for proper reload */
        const queryParam = {};

        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;

        history.push({
            pathname: PreAuthRoutes('promos').path,
            search: `?${queryString.stringify(queryParam)}`
        });

        getNext()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.search])


    /**
     * function to apply the search key words on the promo list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }


    return (
        <PreSignIn>
            <Banner />
            <CardHeader
                title={PreAuthRoutes('promos').name}
                action={
                    <Stack spacing={1} direction="row" alignItems={`center`} >
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                    </Stack>
                } />
            <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                {
                    featured_promos.map((promo, index) => {
                        return <PromoCard key={index} promo={promo} />
                    })
                }
                {isLoading && <PromoCardLoader />}
                {!isLoading && featured_promos.length === 0 && <NoData content1={`Promos`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`15vh`} />}
            </Grid>
        </PreSignIn>
    )
}

export { FeaturedPromos }