import { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { PreAuthMainComponents, PrivateRoute } from './routes';
import { APP_NAME } from './_helpers';
import { AppNameContext } from './_contexts';
import { GlobalAction } from './redux/actions';
import { PostSignIn } from './_layouts/post-signin';

import { ThemeProvider } from '@mui/system';
import { CssBaseline, useMediaQuery } from '@mui/material';
import themeLight from './_theme/theme-light'
import themeDark from './_theme/theme-dark'
import { NotFound, PageLoader, InitialLoader, Toast } from './_components';
import './App.scss';

const menuRoutes = Object.values(PreAuthMainComponents());

function App() {

	/** Initialize hooks, plugins and constants */
	const dispatch = useDispatch();


	/** Redux reducer states */
	const { pageLoader, initialLoader, toastMessage: toast, theme } = useSelector((state) => state.GlobalReducer);

	/** Initialize and declare state */
	const [isDarkMode, setIsDarkMode] = useState(theme)

	useEffect(() => {
		dispatch(GlobalAction.initialLoader(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//========================================
	// User current browser theme will fetched by the media query.
	// Based on the user browser color scheme, app will switch
	// to dark theme or light theme.
	//========================================
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	useMemo(() => setIsDarkMode(theme === 'dark' ? true : ((theme === 'light') ? (false) : prefersDarkMode)), [prefersDarkMode, theme]);

	return (
		<AppNameContext.Provider value={APP_NAME}>
			<HelmetProvider>
				<ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
					<CssBaseline />
					<Switch>
						{menuRoutes.map((route, index) => (
							<Route exact key={index} path={route.path} component={route.component} />
						))}
						<PrivateRoute path='/' component={PostSignIn}></PrivateRoute>
						<Route component={NotFound} />
					</Switch>
					<PageLoader loader={pageLoader} />
					<InitialLoader loader={initialLoader} />
					<Toast message={toast} />
				</ThemeProvider>
			</HelmetProvider>
		</AppNameContext.Provider>
	);
}

export { App };
