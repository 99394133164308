import { Home, Person } from "@mui/icons-material";
import { CouponCodes } from "../app/coupon-codes";
import { GiveawayDetails, Giveaways } from "../app/giveaways";
import { PromoDetails, Promos } from "../app/promos";
import { StoriesDetail } from "../app/stories";

const allRoutes = {
    'promos': {
        path: "/promos",
        name: "Promos",
        component: Promos,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'promo': {
        path: "/promos/detail",
        name: "Promo Details",
        component: PromoDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'promos',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'giveaways': {
        path: "/giveaways",
        name: "Giveaways",
        component: Giveaways,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'giveaway': {
        path: "/giveaways/detail",
        name: "Giveaways Details",
        component: GiveawayDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'giveaways',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    // 'stories': {
    //     path: "/stories",
    //     name: "My stories",
    //     component: Stories,
    //     layout: "/post-auth",
    //     isMenuItem: true,
    //     menuIcon: Home,
    //     module: 'stories',
    //     isModuleMenuItem: true,
    //     isMainComponent: true
    // },
    'story_detail': {
        path: "/stories/detail",
        name: "Promo Details",
        component: StoriesDetail,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'promos',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'coupon_codes': {
        path: "/coupon-codes",
        name: "Coupon Codes",
        component: CouponCodes,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'stories',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        component: Home,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,
    },
    /** begin::change-password module */
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        layout: "/post-auth",
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::change-password module */

    /** begin::account status module */
    'account_status': {
        path: "/profile/status",
        name: "Account Status",
        layout: "/post-auth",
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::account status module */
};

function PostAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PostAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PostAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PostAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getPostComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}


export { PostAuthRoutes, PostAuthMenuRoutes, PostAuthModuleMenuRoutes, PostAuthMainComponents, getPostComponentNameByPath };
