import React from 'react'
import { ContentCopy } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { GlobalAction } from '../../redux/actions';
const copy = require('clipboard-copy')


function SocialShare({ url, title }) {

    const dispatch = useDispatch();

    const copyToClipBoard = (e) => {
        e.preventDefault();
        copy(url);
        dispatch(GlobalAction.showToastMessage(`Copied to clipboard.`))
    }

    return (

        <Stack direction={`row`} spacing={1} sx={{ mt: `7px` }}>
            <IconButton aria-label="delete" size="small" onClick={copyToClipBoard} >
                <ContentCopy size={32} />
            </IconButton>
            <EmailShareButton url={url} subject={title} body="body">
                <EmailIcon size={32} />
            </EmailShareButton>
            <FacebookShareButton url={url} quote={title} >
                <FacebookIcon size={32} />
            </FacebookShareButton>
            <LinkedinShareButton url={url}>
                <LinkedinIcon size={32} />
            </LinkedinShareButton>
            <WhatsappShareButton url={url} title={title} separator=":: "   >
                <WhatsappIcon size={32} />
            </WhatsappShareButton>
        </Stack>
    )
}

export { SocialShare }