
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Container, CssBaseline } from '@mui/material';
import { cookieServices } from '../../_helpers';
import { Header, Footer } from '.'
import { PostAuthRoutes } from '../../routes';


const PreSignIn = ({ children, ...props }) => {

    /** Declare hooks and plugins */
    let history = useHistory();

    useEffect(() => {
        if (cookieServices.get('accessToken'))
            history.push(PostAuthRoutes('promos').path);

    }, [history])


    return (
        <React.Fragment>
            <Header />
            <CssBaseline />
            <Container component="main" maxWidth={false} sx={{ padding: { xs: '0 15px' }, marginBottom: `30px` }}>
                {children}
            </Container>
            <Footer />
        </React.Fragment>
    )
}


export { PreSignIn }
