import { StoriesServices } from "../services";

export const STORIES_REQUEST = 'STORIES_REQUEST';
export const STORIES_SUCCESS = 'STORIES_SUCCESS';
export const STORIES_FAILURE = 'STORIES_FAILURE';

export const STORY_DETAIL_REQUEST = 'STORY_DETAIL_REQUEST';
export const STORY_DETAIL_SUCCESS = 'STORY_DETAIL_SUCCESS';
export const STORY_DETAIL_FAILURE = 'STORY_DETAIL_FAILURE';

export const STORY_TIMELINE_REQUEST = 'STORY_TIMELINE_REQUEST';
export const STORY_TIMELINE_SUCCESS = 'STORY_TIMELINE_SUCCESS';
export const STORY_TIMELINE_FAILURE = 'STORY_TIMELINE_FAILURE';

export const STORY_PROMO_DETAIL_REQUEST = 'STORY_PROMO_DETAIL_REQUEST';
export const STORY_PROMO_DETAIL_SUCCESS = 'STORY_PROMO_DETAIL_SUCCESS';
export const STORY_PROMO_DETAIL_FAILURE = 'STORY_PROMO_DETAIL_FAILURE';

export const StoriesAction = {

    /**
     * Action for list promos
     * @param null
     * @author Akshay 
     */
    listStories: (params) => {
        return dispatch => {
            dispatch(request());
            return StoriesServices.listStories(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request() { return { type: STORIES_REQUEST } }
        function success(request, response) { return { type: STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function to get stories details
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getStoryDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoriesServices.getStoryDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: STORY_DETAIL_REQUEST, request } }
        function success(request, response) { return { type: STORY_DETAIL_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_DETAIL_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
    /**
     * function to get stories timeline
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getStoryTimeline: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoriesServices.getStoryTimeline(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: STORY_TIMELINE_REQUEST, request } }
        function success(request, response) { return { type: STORY_TIMELINE_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_TIMELINE_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    getStoryPromoDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return StoriesServices.getStoryPromoDetails(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: STORY_PROMO_DETAIL_REQUEST, request: request } }
        function success(request, response) { return { type: STORY_PROMO_DETAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: STORY_PROMO_DETAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    }

};
