/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base colors for the Material Dashboard 2 PRO React.
 * You can add new color using this file.
 * You can customized the colors for the entire Material Dashboard 2 PRO React using thie file.
 */

const colors = {

  background: {
    default: "#051A29",
    sidenav: "#1F283E",
    card: "#102A3E",
  },

  text: {
    main: "#D2D2D2",
    focus: "#D2D2D2",
  },


  white: {
    main: "#FFFFFF",
    focus: "#D7DCBB",
  },

  black: {
    main: "#000000",
    focus: "#333333",
  },

  primary: {
    main: "#2C5285",
    focus: "#23426A",
  },

  secondary: {
    main: "#EA3661",
    focus: "#BB2B4E",
  },

  info: {
    main: "#0AAEF2",
    focus: "#088BC2",
  },

  success: {
    main: "#4CAF50",
    focus: "#3D8C40",
  },

  warning: {
    main: "#FB8C00",
    focus: "#C97000",
  },

  error: {
    main: "#AD1457",
    focus: "#8A1046",
  },

  light: {
    main: "#F0F2F5",
    focus: "#C0C2C4",
  },

  dark: {
    main: "#102A3E",
    focus: "#0d2232",
  },

  link: {
    main: "#EA3661",
    focus: "#BB2B4E",
  },

  transparent: {
    main: "transparent",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#2C5285",
      state: "#23426A",
    },

    secondary: {
      main: "#EA3661",
      state: "#BB2B4E",
    },

    info: {
      main: "#0AAEF2",
      state: "#088BC2",
    },

    success: {
      main: "#4CAF50",
      state: "#3D8C40",
    },

    warning: {
      main: "#FB8C00",
      state: "#C97000",
    },

    error: {
      main: "#AD1457",
      state: "#8A1046",
    },

    light: {
      main: "#F0F2F5",
      state: "#C0C2C4",
    },

    dark: {
      main: "#102A3E",
      state: "#04131D",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3B5998",
      dark: "#344E86",
    },

    twitter: {
      main: "#55ACEE",
      dark: "#3EA1EC",
    },

    instagram: {
      main: "#125688",
      dark: "#0E456D",
    },

    linkedin: {
      main: "#0077B5",
      dark: "#00669C",
    },

    pinterest: {
      main: "#CC2127",
      dark: "#B21D22",
    },

    youtube: {
      main: "#E52D27",
      dark: "#D41F1A",
    },

    vimeo: {
      main: "#1AB7EA",
      dark: "#13A3D2",
    },

    slack: {
      main: "#3AAF85",
      dark: "#329874",
    },

    dribbble: {
      main: "#EA4C89",
      dark: "#E73177",
    },

    github: {
      main: "#24292E",
      dark: "#171A1D",
    },

    reddit: {
      main: "#FF4500",
      dark: "#E03D00",
    },

    tumblr: {
      main: "#35465C",
      dark: "#2A3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#2C5285",
      text: "#EAEEF3",
    },

    secondary: {
      background: "#EA3661",
      text: "#EAEEF3",
    },

    info: {
      background: "#0AAEF2",
      text: "#EAEEF3",
    },

    success: {
      background: "#4CAF50",
      text: "#EAEEF3",
    },

    warning: {
      background: "#FB8C00",
      text: "#EAEEF3",
    },

    error: {
      background: "#AD1457",
      text: "#EAEEF3",
    },

    light: {
      background: "#F0F2F5",
      text: "#1A2434",
    },

    dark: {
      background: "#1A2434",
      text: "#F0F2F5",
    },
  },

  coloredShadows: {
    primary: "#416391",
    secondary: "#EC4A71",
    info: "#23B6F3",
    success: "#5EB762",
    warning: "#FB981A",
    error: "#B52C68",
    light: "#F2F3F6",
    dark: "#313A48",
  },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },

  formInput: {
    background: "#143347",
    border: "#143347"

  }
};

export default colors;
