import React, { useEffect, useState } from 'react';
import { Card, CardMedia } from '@mui/material';
import { GlobalAction } from '../../../redux/actions';

function HomeBanner() {


    const [banner, setBanner] = useState(null);

    /** Redux actions and state */
    useEffect(() => {
        GlobalAction.getBannerImage().then((image) => {
            if (image.status) {
                setBanner(image.data.advertisement_banner)
            }
        })
        //eslint-disable-next-line
    }, [])

    return (
        banner ?
            (<Card >
                <CardMedia style={{ padding: 0, margin: 0 }} component="img" image={banner} />
            </Card>) : ''
    );
}

export { HomeBanner };
