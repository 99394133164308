import { cookieServices } from "../../_helpers";
import {
	SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE,
	SIGNOUT_REQUEST, SIGNOUT_SUCCESS, SIGNOUT_FAILURE
} from "../actions";

let isLoggedIn = cookieServices.get('accessToken') ? (cookieServices.get('accessToken')) : false;
const initialState = isLoggedIn ? { loggedIn: true, errors: {}, password_errors: {} } : { loggedIn: false, request: {}, errors: {}, };

export function SignInReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign in redux */
		case SIGNIN_REQUEST: {
			return {
				loading: true,
				loggedIn: false,
				request: action.request
			};
		}
		case SIGNIN_SUCCESS: {
			return {
				loading: false,
				loggedIn: true,
			};
		}
		case SIGNIN_FAILURE: {
			return {
				loading: false,
				loggedIn: false,
				errors: action.errors
			};
		}
		/** end::Sign in redux */

		/** begin::Sign out redux */
		case SIGNOUT_REQUEST: {
			return {
				loading: true
			};
		}
		case SIGNOUT_SUCCESS: {
			return {
				loading: false,
				loggedIn: false,
			};
		}
		case SIGNOUT_FAILURE: {
			return {
				loading: false,
				loggedIn: false
			};
		}
		/** end::Sign out redux */

		default:
			return state;
	}
}
