import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent } from '@mui/material'

function ModalBody({ className, children, ...props }) {
    return (
        <DialogContent className={`${className}`} {...props}>
            {children}
        </DialogContent>
    )
}


ModalBody.defaultProps = {
    className: ""
}


ModalBody.propTypes = {
    className: PropTypes.string,
}

export { ModalBody }
